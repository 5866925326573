import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./landing.css";
import AOS from "aos";
import $ from "jquery";
class Dashboard2 extends Component {
  constructor() {
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      pLogo: "",
      cssfiles: [
        "./home/assets/vendor/bootstrap/css/bootstrap.min.css",
        "./home/assets/vendor/bootstrap-icons/bootstrap-icons.css",
        "./home/assets/vendor/aos/aos.css",
        "./home/assets/vendor/remixicon/remixicon.css",
        "./home/assets/vendor/swiper/swiper-bundle.min.css",
        "./home/assets/vendor/glightbox/css/glightbox.min.css",
        "./home/assets/css/style.css",
      ],
      jsfiles: [
        "./home/assets/vendor/bootstrap/js/bootstrap.bundle.js",
        "./home/assets/vendor/aos/aos.js",
        "./home/assets/vendor/php-email-form/validate.js",
        "./home/assets/vendor/swiper/swiper-bundle.min.js",
        "./home/assets/vendor/purecounter/purecounter.js",
        "./home/assets/vendor/isotope-layout/isotope.pkgd.min.js",
        "./home/assets/vendor/glightbox/js/glightbox.min.js",
        "./home/assets/js/main.js",
      ],

      //AOS.init();
    };
  }

  componentDidMount() {
    AOS.init();
    let url1 = window.location.href;
    if (url1 == "https://app.symcarelab.com/") {
      window.location.href = "https://www.symcarelab.com/";
    }
    if (localStorage.getItem("meow") != "ok") {
      $("#overlay").show();
    }
  }
  meow() {
    localStorage.setItem("meow", "ok");
    $("#overlay").hide();
  }
  loginpage() {
    localStorage.clear();
    //sessionStorage.clear()
    window.location.href = "/login";
  }
  submit() {}
  // menuu(){
  //     console.log("menu te dhukechi")
  //     if(this.state.colapsed==1){
  //         document.getElementById("menubarkor").removeAttribute("class")
  //         document.getElementById("menubarkor").setAttribute("class","main-menu menu-fixed menu-light menu-accordion menu-shadow")

  //         document.getElementById("root").removeAttribute("class")

  //         document.getElementById("root").setAttribute("class","vertical-layout navbar-floating footer-static pace-done vertical-menu-modern menu-collapsed")

  //         this.setState({colapsed:2})
  //      }
  //     else{
  //      document.getElementById("menubarkor").removeAttribute("class")
  //      document.getElementById("menubarkor").setAttribute("class","main-menu menu-fixed menu-light menu-accordion menu-shadow expanded")
  //      document.getElementById("root").removeAttribute("class")

  //      document.getElementById("root").setAttribute("class","vertical-layout navbar-floating footer-static pace-done vertical-menu-modern menu-expanded")

  //      this.setState({colapsed:1})
  //     }
  // }
  opennav() {
    if (document.getElementById("navbar").className == "navbar") {
      document.getElementById("navbar").className = "navbar navbar-mobile";
      document.getElementById("xx").className = "bi mobile-nav-toggle bi-x";
    } else {
      document.getElementById("navbar").className = "navbar";
      document.getElementById("xx").className = "bi mobile-nav-toggle bi-list";
    }
  }
  render() {
    return (
      <div>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Nunito:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />

        <link rel="stylesheet" type="text/css" href={this.state.cssfiles[0]} />
        <link rel="stylesheet" type="text/css" href={this.state.cssfiles[1]} />
        {/* <link rel="stylesheet" type="text/css" href={this.state.cssfiles[2]} /> */}
        <link rel="stylesheet" type="text/css" href={this.state.cssfiles[3]} />
        <link rel="stylesheet" type="text/css" href={this.state.cssfiles[4]} />
        <link rel="stylesheet" type="text/css" href={this.state.cssfiles[5]} />
        <link rel="stylesheet" type="text/css" href={this.state.cssfiles[6]} />
        <header id="header" class="header fixed-top p-0 ">
          <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
            <a href="index.html" class="logo d-flex align-items-center">
              <img src="../../../home/assets/img/logo.png" alt="" />
            </a>
            {/* <nav id="navbar" class="navbar">
        <ul>
          <li><a class="getstarted scrollto" href="#about">login</a></li>
          <li><a class="getstarted scrollto" href="#about">Register</a></li>
        </ul>
        <i class="bi mobile-nav-toggle bi-list"></i>
      </nav> */}

            <nav id="navbar" class="navbar ">
              <ul>
                <li>
                  <a
                    class="getstarted scrollto"
                    onClick={() => this.loginpage()}
                  >
                    &nbsp;&nbsp;&nbsp;{window.$language.Login}&nbsp;&nbsp;&nbsp;
                  </a>
                </li>

                {/* <li><a class="getstarted scrollto" href="/registration">&nbsp;&nbsp;&nbsp;{window.$language.Register}&nbsp;&nbsp;&nbsp;</a></li> */}
                <li class="nav-item dropdown dropdown-language d-none ">
                  <a
                    class="getstarted scrollto nav-link dropdown-toggle"
                    id="dropdown-flag"
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="selected-language">French</span>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdown-flag"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      data-language="en"
                    >
                      English
                    </a>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      data-language="fr"
                    >
                      French
                    </a>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      data-language="de"
                    >
                      German
                    </a>
                  </div>
                </li>
              </ul>
              <i
                class="bi mobile-nav-toggle bi-list"
                id="xx"
                onClick={() => this.opennav()}
              ></i>
            </nav>
          </div>
        </header>
        <section id="hero" class="hero d-flex align-items-center pt-5 mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 d-flex flex-column justify-content-center">
                <h1 data-aos="fade-up">Sym Care Lab</h1>
                <h2 data-aos="fade-up" data-aos-delay="400">
                  Nous réinventons le parcours de santé optique de la
                  prescription à l’achat de lunettes
                </h2>

                <div data-aos="fade-up" data-aos-delay="600">
                  <img
                    class="w-50 mt-4"
                    src="../../../home/assets/img/logo21.png"
                  />
                  {/* <div class="text-center text-lg-start px-4">
                    <a
                      href="https://calendly.com/symvisionlab/locauxsym?month=2021-05"
                      class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Prendre RDV</span>
                      <i class="bi bi-arrow-right"></i>
                    </a>
                  </div> */}
                </div>
              </div>
              <div
                class="col-lg-6 hero-img "
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <div class="pl-0 ml-0 mt-5">
                  <img
                    class="img-fluid5 "
                    src="../../../home/assets/img/Landingpage_Mainimage.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="values" class="values">
          <div class="container" data-aos="fade-up">
            <header class="section-header">
              <p>Notre offre</p>
              <h3>Nous proposons un parcours rapide et intégré</h3>
            </header>
            <div class="justify-content-center d-flex py-3 "></div>
            <div class="row">
              <div class="col-lg-4">
                <div class="box" data-aos="fade-up" data-aos-delay="200">
                  <img
                    src="../../../home/assets/img/service_1.png"
                    class="img-fluid"
                    alt=""
                  />
                  <h3>Test de vue par votre opticien diplômé</h3>
                  <p>
                    Examen de réfraction en boutique télétransmis grâce à notre
                    plateforme
                  </p>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="400">
                  <img
                    src="../../../home/assets/img/service_2.png"
                    class="img-fluid"
                    alt=""
                  />
                  <h3>
                    Plateforme e-santé permettant la collecte et la
                    télétransmission des tests de refraction
                  </h3>
                  <p>
                    Revue des mesure par un ophtamologiste en téléconsultation
                    asynchrone
                  </p>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="box" data-aos="fade-up" data-aos-delay="600">
                  <img
                    src="../../../home/assets/img/service_3.png"
                    class="img-fluid"
                    alt=""
                  />
                  <h3>
                    Emission d'une ordonannce en 48h par un de nos
                    ophtamologistes partenaires
                  </h3>
                  <p>
                    Téléchargement de l'ordonnance directement dans un espace
                    personnel sécurisé sur la plateforme
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="about" class="about p-0">
            <div class="container" data-aos="fade-up">
              <div class="row gx-0">
                <div
                  class="col-lg-5 d-flex flex-column justify-content-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div class="content">
                    <h2>Un parcours réinventé</h2>
                    <p>
                      Le parcours traditionnel de prescription de lunettes est
                      souvent long et exigeant, voire couteux. Nous proposons un
                      nouveau parcours révolutionnaire tout-en-un , avec test de
                      vue, émission d'ordonnance en téléconsultation asynchrone,
                      mise à disposition de l'ordonnance sur un espace personnel
                      sécurisé.
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-7 d-flex align-items-center"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src="../../../home/assets/img/about.png"
                    class="img-fluid22"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="team" class="team">
            <div class="container" data-aos="fade-up">
              <header class="section-header">
                <p>Nos clients parlent de nous</p>
              </header>

              <div class="row gy-4">
                <div
                  class="col-lg-4 col-md-6 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div class="member">
                    <div class="member-img">
                      <img
                        src="../../../home/assets/img/team/Testimonial_1.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="social"></div>
                    </div>
                    <div class="member-info">
                      <h4>Rachid, 59 ans</h4>
                      <p>
                        « Je n’étais pas allée chez l’ophtalmo depuis quelques
                        années. J’ai pu avoir un rdv rapidement, près de chez
                        moi, et mon ordonnance était prête le lendemain ! »
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-4 col-md-6 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div class="member">
                    <div class="member-img">
                      <img
                        src="../../../home/assets/img/team/Testimonial_2.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="social"></div>
                    </div>
                    <div class="member-info">
                      <h4>Hortense, 64 ans</h4>
                      <p>
                        « L’opticien s’est très bien occupé de moi et m’a
                        conseillé pour choisir une paire de lunettes. J’ai même
                        pris une paire de verres solaire en plus ! »
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-4 col-md-6 d-flex align-items-stretch"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div class="member">
                    <div class="member-img">
                      <img
                        src="../../../home/assets/img/team/Testimonial_3.png"
                        class="img-fluid"
                        alt=""
                      />
                      <div class="social"></div>
                    </div>
                    <div class="member-info">
                      <h4>Lucie, 24 ans</h4>
                      <p>« Service au top, je suis ravie »</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="contact" class="contact">
            <div class="container" data-aos="fade-up">
              <header class="section-header">
                <h2>Nous contacter</h2>
                <p>Nous contacter</p>
              </header>

              <div class="row gy-4">
                <div class="col-lg-6">
                  <div class="row gy-4">
                    <div class="col-md-6">
                      <div class="info-box">
                        <i class="bi bi-geo-alt"></i>
                        <h3>Adresse</h3>
                        <p>
                          24 Rue de Châteaudun,
                          <br />
                          75009 Paris
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="info-box">
                        <i class="bi bi-telephone"></i>
                        <h3>Appelez-nous</h3>
                        <p>01 42 18 11 84</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="info-box">
                        <i class="bi bi-envelope"></i>
                        <h3>Envoyez-nous un email</h3>
                        <p>
                         contact@symvisionlab.fr
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="info-box">
                        <i class="bi bi-clock"></i>
                        <h3>Heures d'ouverture</h3>
                        <p>
                          Lundi - Vendredi
                          <br />
                          10h - 18h
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <form
                    action="forms/contact.php"
                    method="post"
                    class="php-email-form"
                  >
                    <div class="row gy-4">
                      <div class="col-md-6">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          placeholder="Nom"
                          required
                        />
                      </div>

                      <div class="col-md-6 ">
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          placeholder=" E-mail"
                          required
                        />
                      </div>

                      <div class="col-md-12">
                        <input
                          type="text"
                          class="form-control"
                          name="subject"
                          placeholder="Objet"
                          required
                        />
                      </div>

                      <div class="col-md-12">
                        <textarea
                          class="form-control"
                          name="message"
                          rows="6"
                          placeholder="Un message"
                          required
                        ></textarea>
                      </div>

                      <div class="col-md-12 text-center">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">
                          Your message has been sent. Thank you!
                        </div>

                        <button type="submit" href="#">
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer" class="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row gy-4">
                <div class="col-lg-5 col-md-12 footer-info">
                  <a href="index.html" class="logo d-flex align-items-center">
                    <img src="../../../home/assets/img/logo.png" alt="" />
                    <span></span>
                  </a>
                  <p>
                    Sym Care Lab révolutionne le parcours de soins optique en
                    proposant des rdvs rapidement puis une télé consultation
                    asynchrone des résultats des tests de vue par un
                    ophtalmologiste, donnant lieu à une émission d’ordonnance.
                    Retrouvez-nous vite sur un de nos points de consultation ou
                    à notre showroom au 24 rue de Châteaudun à Paris.{" "}
                  </p>
                  <div class="social-links mt-3">
                    <a href="#" class="twitter">
                      <i class="bi bi-twitter"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/symopticofficiel"
                      class="facebook"
                    >
                      <i class="bi bi-facebook"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/symoptic/"
                      class="instagram"
                    >
                      <i class="bi bi-instagram bx bxl-instagram"></i>
                    </a>
                    <a href="#" class="linkedin">
                      <i class="bi bi-linkedin bx bxl-linkedin"></i>
                    </a>
                  </div>
                </div>

                <div class="col-lg-2 col-6 footer-links">
                  <h4>LIENS UTILES</h4>
                  <ul>
                    <li>
                      <i class="bi bi-chevron-right"></i> <a href="#">Home</a>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>{" "}
                      <a href="#">À propos de nous</a>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>{" "}
                      <a href="#">Points de vente</a>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>{" "}
                      <a href="#">Produits</a>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>{" "}
                      <a
                        href="../../../home/assets/Terms.pdf"
                        class="instagram"
                      >
                        Termes et Conditions
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-6 footer-links">
                  <h4>NOS SERVICES</h4>
                  <ul>
                    <li>
                      <i class="bi bi-chevron-right"></i>{" "}
                      <a href="#">Nous rejoindre</a>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>{" "}
                      <a href="#">Nous contacter</a>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right"></i>{" "}
                      <a href="#">Conditions de vente</a>
                    </li>
                    <li>
                      <i class="bi bi-chevron-right "></i>{" "}
                      <a
                        href="../../../home/assets/PrivacyPolicy.pdf"
                        class="instagram"
                      >
                        Politique de confidentialité
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                  <h4>ADRESSE</h4>
                  <p>
                    24 Rue de Châteaudun,
                    <br /> 75009 Paris <br />
                    <br />
                    <strong>TÉLÉPHONE:</strong> 01 42 18 11 84
                    <br />
                    <strong>Email:</strong> info@sym-optic.com
                    <br />
                    <strong>Web:</strong> sym-optic.com
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Sym Optic</span>
              </strong>
              . All Rights Reserved.
            </div>
            <div class="credits">Version-2.4.15
            </div>
          </div>
        </footer>
        <a
          href="#"
          class="back-to-top d-flex align-items-center justify-content-center"
        >
          <i class="bi bi-arrow-up-short"></i>
        </a>
        <script type="text/javascript" src={this.state.jsfiles[0]}></script>
        {/* <script type="text/javascript" src={this.state.jsfiles[1]}></script> */}
        <script type="text/javascript" src={this.state.jsfiles[2]}></script>
        <script type="text/javascript" src={this.state.jsfiles[3]}></script>
        <script type="text/javascript" src={this.state.jsfiles[4]}></script>
        <script type="text/javascript" src={this.state.jsfiles[5]}></script>
        <script type="text/javascript" src={this.state.jsfiles[6]}></script>
        <script type="text/javascript" src={this.state.jsfiles[7]}></script>
        <div id="overlay" class="cookie-consent">
          {" "}
          <span>
            Nous utilisons des cookies pour vous garantir la meilleure
            navigation possible et pour réaliser des statistiques de visite afin
            d'améliorer votre expérience. A noter que nous n'utilisons de
            cookies ni pour du ciblage marketing ni pour proposer des offres ou
            des services personnalisés et que par ailleurs, aucune donnée n'est
            commuiquée à des tiers, même anonymisée.&nbsp;
            <a
              href="../../../home/assets/PrivacyPolicy.pdf"
              class="text-decoration-none instagram"
            >
              <u>
                Pour plus d'informations, consultez notre politique de
                confidentialité.
              </u>
            </a>{" "}
          </span>
          <div class="mt-2 d-flex align-items-center justify-content-center g-2">
            {" "}
            <button class="allow-button mr-1" onClick={() => this.meow()}>
              Accepter et Fermer
            </button>{" "}
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard2;
